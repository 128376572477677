import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

async function sleep() {
  return new Promise(resolve => {
    window.requestAnimationFrame(resolve);
  })
}

export const Hanabi: React.VFC<{}> = () => {
  const divRef = useRef<HTMLDivElement>(null!);
  const canvasRef = useRef<HTMLCanvasElement>(null!);
  const [size, setSize] = useState<{ w: number; h: number; }>({ w: 0, h: 0 });

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setSize({ w: entry.contentRect.width, h: entry.contentRect.height });
      }
    });
    const div = divRef.current;
    observer.observe(div);
    return () => {
      observer.unobserve(div);
    };
  }, []);

  useEffect(() => {
    const cvs = canvasRef.current;
    const ctx = cvs.getContext("2d")!;
    let unmounted = false;

    type Sphere = {
      x: number;
      y: number;
      size: number;
      v: Vector;
      angle: number;
      speed: number;
      color: string;
    };
    type Vector ={
      x: number;
      y: number;
    }

    function draw(ctx: CanvasRenderingContext2D, s: Sphere) {
      ctx.beginPath();
      ctx.moveTo(s.x, s.y);
      const sz = s.size < 2 ? s.size : 2;
      ctx.ellipse(s.x, s.y, s.size, sz, s.angle, 0, Math.PI * 2);
      ctx.closePath();
      ctx.fillStyle = s.color;
      ctx.fill();
    }

    function makeSpheres(
      center: { x: number, y: number; },
      min: number,
      max: number,
      count: number,
      colors: string[],
    ) {
      let objects: Sphere[] = [];
      for (let i = 0; i <= Math.PI * 2; i += Math.PI * 2 / count) {
        const speed = min + Math.random() * (max - min);
        objects.push({
          x: center.x,
          y: center.y - 10,
          size: 12,
          v: { x: Math.cos(i) * speed, y: Math.sin(i) * speed },
          angle: i,
          speed,
          color: colors[~~(Math.random() * colors.length)],
        });
      }
      return objects;
    }

    const COLORS1 = ["#f3908f", "#96fb9c", "#5bafed"];
    const COLORS2 = ["#ffe8a6"];

    async function drawHanabi(center: { x: number; y: number; }) {
      let objects: Sphere[] = [];
      objects = [...objects, ...makeSpheres(center, 3.4, 3.5, 32, COLORS1.slice(0, 1))];
      objects = [...objects, ...makeSpheres(center, 2.1, 3.4, 96, COLORS1)];
      objects = [...objects, ...makeSpheres(center, 2.0, 2.1, 32, COLORS1)];
      objects = [...objects, ...makeSpheres(center, 1.4, 2.0, 32, COLORS2)];
      objects = [...objects, ...makeSpheres(center, 1.0, 1.4, 32, COLORS2)];
      objects = [...objects, ...makeSpheres(center, 0.0, 1.0, 16, COLORS2)];
      for (const obj of objects) {
        draw(ctx, obj);
      }
      while (objects.length) {
        for (const obj of objects) {
          obj.x += obj.v.x;
          obj.y += obj.v.y;
          obj.v = {
            x: Math.cos(obj.angle) * obj.speed,
            y: Math.sin(obj.angle) * obj.speed + 0.1,
          };
          obj.speed *= 0.95;
          obj.size *= 0.975;
        }
        objects = objects.filter(o => o.size >= 0.1);
        await sleep();
        ctx.clearRect(center.x - 100, center.y - 100, 200, 200);
        if (unmounted) {
          return;
        }
        for (const obj of objects) {
          draw(ctx, obj);
        }
        ctx.fill();
      }
    }

    async function makeRandomPoint(points: { x: number; y: number; }[]) {
      while (true) {
        const point = {
          x: Math.random() * (size.w - 200) + 100,
          y: Math.random() * (size.h - 200) + 100,
        };
        let ok = true;
        for (const p of points) {
          if (Math.abs(point.x - p.x) > 200 || Math.abs(point.y - p.y) > 200) {
          } else {
            ok = false;
            break;
          }
        }
        if (ok) {
          return point;
        }
        await sleep();
      }
    }

    // ここけっこうカオス
    (async () => {
      const promises: Promise<void>[] = [];
      const points: { x: number; y: number; }[] = [];
      while (true) {
        const point = await makeRandomPoint(points);
        if (unmounted) {
          return;
        }
        points.push(point);
        promises.push(
          (async () => {
            await drawHanabi(point);
            promises.splice(0, 1);
            points.splice(0, 1);
          })(),
        );
        await new Promise(r => window.setTimeout(r, Math.random() * 900 + 100));
      }
    })();
    return () => {
      unmounted = true;
    };
  }, [size]);

  return (
    <div ref={divRef} style={{
      width: "100%",
      height: "100vh",
    }}>
      <canvas ref={canvasRef} width={size.w} height={size.h} style={{
        backgroundColor: "black",
        pointerEvents: "none",
      }} />
    </div>
  );
};

export default Hanabi;
